<template>
  <div class="row">
    <div class="col-lg-12" v-for="(item, index) in headerTitle" :key="index">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ item.name }}</h4>
        </template>
        <template v-slot:body>
          <p>{{ item.text }}</p>
        </template>
      </iq-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      headerTitle: [
        {
          name: '我們收集的個人數據',
          text: '資料準備中.....'
        },
        {
          name: '您關於個人數據的權利',
          text: '資料準備中.....'
        },
        {
          name: '保護個人數據',
          text: '資料準備中.....'
        },
        {
          name: '有關個人數據和服務的其他重要信息',
          text: '資料準備中.....'
        },
        {
          name: '本隱私聲明的修改和更新 本隱私聲明的',
          text: '資料準備中.....'
        },
        {
          name: '附加信息和幫助',
          text: '資料準備中.....'
        }
      ]
    }
  }
}
</script>
